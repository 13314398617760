import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { SEO } from 'components';
import { BlogPostNode, BlogTagNode, BlogCategoryNode } from 'types/blog';
import { Locale, usePublicTranslation } from 'features/localization';
import { Container } from '@aviationexam/core';
import { CategoryNavigation, PostList } from './components';

interface DataType {
  allApiBlogPost: { nodes: BlogPostNode[] };
  apiBlogTag: BlogTagNode;
  allApiBlogCategory: { nodes: BlogCategoryNode[] };
}

interface Context {
  locale: Locale;
  pagination: {
    next: string;
    previous: string;
  };
}

export default function Tag({
  data: {
    allApiBlogPost: { nodes: posts },
    apiBlogTag: tag,
    allApiBlogCategory: { nodes: categories },
  },
  pageContext: { locale, pagination },
}: PageProps<DataType, Context>) {
  const { t } = usePublicTranslation();

  return (
    <>
      <SEO title={tag.title} description={t('site.description')} lang={locale}>
        {tag.alternateUrls.map(alternateUrl => (
          <link
            key={alternateUrl.route}
            rel="alternate"
            hrefLang={alternateUrl.locale}
            href={alternateUrl.route}
          />
        ))}
      </SEO>
      <CategoryNavigation categories={categories} />
      <Container size="xl">
        <PostList
          title={t('blogPage.tagHeading', { tag: tag.title })}
          posts={posts}
          nextPage={pagination.next}
          previousPage={pagination.previous}
        />
      </Container>
    </>
  );
}

export const pageQuery = graphql`
  query BlogTagPosts($blogPostIds: [String!], $id: String!, $locale: String!) {
    allApiBlogCategory(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        route
      }
    }
    apiBlogTag(id: { eq: $id }) {
      title
      alternateUrls {
        locale
        route
      }
    }
    allApiBlogPost(
      filter: { id: { in: $blogPostIds } }
      sort: { fields: createdDate, order: DESC }
    ) {
      nodes {
        id
        title
        author
        createdDate
        locale
        route
        description
        alternateUrls {
          locale
          route
        }
        featuredImg {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: BLURRED
              formats: [WEBP, AUTO]
            )
          }
        }
      }
    }
  }
`;
